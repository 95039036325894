import React from 'react'
import faceB from '../assets/imgs/facebB.svg';
import messegB from '../assets/imgs/mesB.svg';
import instaB from '../assets/imgs/instaB.svg';
import arrowUP from '../assets/imgs/arrowUP.svg';
import jeknowledge from '../assets/imgs/jeknowledge.svg';


const Footer = ({local}) => {
  return (
    <div className="primary-footer | bg-primary">
        <div className="container-mobile flex-down flex-mobile-down">  
          <div className='footer-content'>
            <p className="desktop gap-vertical-30 text-tertiary ff-primary fw-regular fs-18">Contacte-nos ou visite-nos!</p>
            <div className="columns-grid gap-mobile-90">
              <div className='vertical'>
                <h2 className="title-black gap-vertical-10">TELEFONE</h2>
                <p className="text-black">+351 913 857 490</p>
                <a href="https://www.livroreclamacoes.pt/Inicio/" target='__blank' className='title-black reclamacoes'>Livro de Reclamações</a>
              </div>
              
              <div className='gap-mobile-30'>
                <h2 className="title-black gap-vertical-10">MORADA</h2>
                <p className="text-black">
                  Alameda da Europa,<br/> 
                  lote 3, loja A<br/>  
                  6200-546 Covilhã
                </p>
              </div>
              <div className='gap-vertical-50 gap-mobile-40'>
                <h2 className="title-black gap-vertical-10">HORÁRIO DE ABERTURA</h2>
                <p className="text-black-bold">Segunda a Sexta</p>
                <p className="text-black gap-vertical-10">12:00-15:00 | 19:30-22:30</p>
                <p className="text-black-bold">Sábado</p>
                <p className="text-black">12:30-15:00 | 19:30-22:30</p>
              </div>
            </div>
            <div className="footer-social gap-vertical-30">
              <ul className="social-links social-scale-1 flex-mobile mobile-social">
                <li><a aria-label="facebook" href="https://www.facebook.com/gtrezerestaurante"><img className='icons-scale' src={faceB} alt="facebook"/></a></li>
                <li><a aria-label="messenger" href="https://www.messenger.com/gtrezerestaurante"><img className='icons-scale' src={messegB} alt="messenger"/></a></li>
                <li><a aria-label="instagram" href="https://www.instagram.com/gtrezerestaurante"><img className='icons-scale' src={instaB} alt="instagram"/></a></li>
              </ul>
            </div>
            <div className='flex-down'>
            <a href="https://jeknowledge.pt/"><img className='logo-scale gap-mobile-85' src={jeknowledge} alt="jeknowledge"/></a>
            </div>
          </div>
          <div className="flex-center flex-mobile mobile-social social-scale-2 gap-mobileb-90">
            <a className='links' href={"#"+local}><p className="text-tertiary ff-primary fw-regular fs-16">Voltar ao topo da página</p></a>
              <ul className="social-links mobile-arrow">
                <li><a href={"#"+local}><img className='icons-scale' src={arrowUP} alt="arrow-up"/></a></li>
              </ul>
          </div>
        </div>
    </div>
  )
}

export default Footer